<template>
  <div style="text-align : center;padding: 50px 0;">
    <img :src="require('../../../assets/developping_images/developping.png')" width="200" style="margin-bottom: 20px;"/>
    <p>暂无数据</p>
  </div>
</template>
<script>
export default {
  data () {
    return {

    };
  }
};
</script>
<style lang="scss" scoped>

</style>
