<template>
	<div v-loading="loading">
		<el-form
			:model="searchForm"
			inline
			ref="form"
			class="searchForm"
			size="small"
			style="background-color: #fff; margin-bottom: 20px; padding: 20px 0 0 18px"
		>
			<el-form-item label="绩效考核名称">
				<el-select v-model="searchForm.performanceId" filterable placeholder="请选择" style="width: 160px" clearable>
					<el-option v-for="item in options" :key="item.value" :label="item.value" :value="item.key"> </el-option>
				</el-select>
				<el-button type="primary" style="margin-left: 10px" size="small" @click="_getList(1)" v-show="orgIds" icon="el-icon-search"
					>查询</el-button
				>
			</el-form-item>
			<el-form-item label="年份" v-show="!orgIds">
				<el-date-picker
					style="width: 160px"
					type="year"
					value-format="yyyy"
					format="yyyy"
					v-model="searchForm.year"
					placeholder="年份"
					clearable
				>
				</el-date-picker>
			</el-form-item>
			<el-form-item label="部门" v-show="!orgIds">
				<treeselect
					:props="depProps"
					:options="deps"
					:value="searchForm.department"
					:clearable="true"
					:accordion="true"
					:level="searchForm.department"
					@getValue="_getValue($event)"
					style="width: 160px"
				/>
			</el-form-item>
			<el-form-item label="绩效等级" v-show="!orgIds">
				<el-select v-model="searchForm.performanceScore" filterable placeholder="请选择" style="width: 160px" clearable>
					<el-option v-for="item in optionsScore" :key="item.value" :label="item.value" :value="item.key"> </el-option>
				</el-select>
			</el-form-item>
			<el-button type="primary" size="small" @click="_getList(1)" v-show="!orgIds" icon="el-icon-search">查询</el-button>
			<el-row class="fr">
				<el-popover placement="bottom" width="335" trigger="click">
					<el-row>
						绩效考核名称
						<el-select
							v-model="downloadId"
							filterable
							clearable
							size="small"
							placeholder="请选择"
							style="width: 160px"
							@change="_download"
						>
							<el-option v-for="item in optionsDownload" :key="item.value" :label="item.value" :value="item.key"> </el-option>
						</el-select>
					</el-row>
					<el-button
						v-show="!orgIds"
						type="primary"
						size="small"
						slot="reference"
						style="margin: 0 10px"
						icon="el-icon-document"
						v-if="permissionControlBtns(pageName, 'Download')"
						>下载模板</el-button
					>
				</el-popover>
				<el-button
					type="primary"
					size="small"
					@click="dialogImportVisible = true"
					v-if="staffIdStatus != null && !orgIds && permissionControlBtns(pageName, 'Import')"
					icon="el-icon-upload2"
					>导入</el-button
				>
				<el-button
					type="primary"
					size="small"
					@click="_export"
					v-if="staffIdStatus != null && !orgIds && permissionControlBtns(pageName, 'Export')"
					icon="el-icon-download"
					>导出</el-button
				>
			</el-row>
		</el-form>
		<div v-if="list.length > 0" :style="!orgIds ? 'margin-top:50px' : ''">
			<el-card class="box-card" v-for="(item, i) in list" :key="i" shadow="never">
				<div class="box-card-top" style="float: left">
					{{ item.performanceName }} <span>操作人：{{ item.createName }}</span>
				</div>
				<el-row
					style="float: right; margin-bottom: 15px"
					v-if="
						item.startStatus != 2 &&
						item.selfEvaluationStatus == 1 &&
						staffIdStatus != null &&
						item.processStatus >= 3 &&
						item.processStatus <= 5
					"
				>
					<el-button type="primary" size="small" v-if="!orgIds" @click="_edit(item, i)">编辑</el-button>
				</el-row>
				<el-row style="float: right; height: 15px; width: 100%" v-else> </el-row>
				<div class="box-card-line"></div>
				<el-table :data="[item]" stripe class="levelTable" :header-cell-style="tableHeaderColor">
					<el-table-column fixed prop="assessCycle" label="绩效周期" show-overflow-tooltip width="200" align="center"> </el-table-column>
					<el-table-column prop="orgName" label="部门" v-if="!orgIds" show-overflow-tooltip align="center"> </el-table-column>
					<el-table-column prop="performanceScoreOrg" label="绩效等级" show-overflow-tooltip align="center"> </el-table-column>
					<el-table-column
						v-for="(col, i) in item.performanceCateNumber"
						:prop="col.level"
						:key="i"
						:label="`${col.level}绩效等级`"
						show-overflow-tooltip
						width="100"
						align="center"
					>
						<template>
							<span :style="{ color: col.score <= 0 ? '#FF604D' : '#484848' }">{{ col.score }}人</span>
						</template>
					</el-table-column>
				</el-table>
			</el-card>
			<el-pagination
				v-if="searchForm.totalNum > 0"
				@size-change="_handleSizeChange"
				@current-change="_handleCurrentChange"
				:current-page="searchForm.pageNo"
				:page-sizes="[20, 40, 80, 100]"
				:page-size="searchForm.pageSize"
				style="text-align: right; padding: 20px 0; background-color: #fff"
				layout="total, sizes, prev, pager, next, jumper"
				:total="searchForm.totalNum"
			>
			</el-pagination>
		</div>
		<NoneCom v-else />
		<ImportCom
			:dialogImportVisible.sync="dialogImportVisible"
			:importUrl="importUrl"
			:type="'performanceLevel'"
			:params="searchForm"
			@handleSearch="_getList(searchForm.pageNo)"
		/>
		<el-dialog :visible.sync="dialogVisible" class="loser" width="900px" center>
			<span slot="title" class="dialog-title">{{ currentForm.id ? '编辑' : '新增' }}组织绩效</span>
			<el-form ref="currentForm" :rules="rulesReturn" inline :model="currentForm" label-width="150px" style="padding-left: 5%" size="mini">
				<el-form-item prop="performanceId" label="绩效考核名称">
					<el-select
						v-if="!currentForm.id"
						v-model="currentForm.performanceId"
						filterable
						placeholder="请选择"
						style="width: 210px"
						@change="_currentSelect"
					>
						<el-option v-for="item in optionsNew" :key="item.value" :label="item.value" :value="item.key"> </el-option>
					</el-select>
					<el-input v-else style="width: 210px" :value="currentForm.performanceName" disabled />
				</el-form-item>
				<el-form-item prop="orgId" label="部门名称">
					<treeselect
						v-if="!currentForm.id"
						:props="depProps"
						:options="deps"
						:value="currentForm.department"
						:clearable="true"
						:accordion="true"
						:level="currentForm.department"
						@getValue="_getValue2($event)"
						style="width: 210px"
					/>
					<el-input v-else style="width: 210px" :value="currentForm.orgName" disabled />
				</el-form-item>
				<el-form-item prop="performanceScoreOrg" label="组织绩效等级">
					<el-select v-model="currentForm.performanceScoreOrg" filterable placeholder="请选择" style="width: 210px">
						<el-option
							v-for="item in currentForm.id ? currentOptionsScore : optionsScoreNew"
							:key="item.value"
							:label="item.value"
							:value="item.key"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-for="(item, index) in currentForm.performanceCateNumber" :key="index" :label="`绩效等级为${item.level}的数量`">
					<el-input style="width: 210px" onkeyup="value=value.replace(/[^\d]/g,'')" v-model="item.score" />
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false" size="medium">取消</el-button>
				<el-button type="primary" size="medium" @click="_save">保存</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import treeselect from '@/components/treeSelect/treeSelect';
import ImportCom from '@/components/import';
import {
	performanceLevelList,
	performanceNameChoice,
	performanceScoreList,
	performanceLevelAdd,
	OrgList,
	performanceLevelUpdate
} from '@/api/performance';
import { isIntegerNotMust } from './utils/validate';
import NoneCom from './components/NoneCom';

export default {
	components: { treeselect, NoneCom, ImportCom },
	props: {
		orgIds: {
			type: Array,
			required: false
		},
		pageName: {}
	},
	data() {
		return {
			searchForm: {
				performanceId: '',
				year: '',
				department: '',
				orgId: '',
				performanceScore: '',
				pageNo: 1,
				pageSize: 20,
				totalNum: 0
			},
			deps: this.$store.state.setData.depsList,
			depProps: {
				value: 'id',
				label: 'orgName',
				children: 'children'
			},
			list: [],
			options: [],
			optionsNew: [],
			optionsScore: [],
			optionsScoreNew: [],
			optionsDownload: [],
			downloadId: '',
			dialogImportVisible: false,
			dialogVisible: false,
			importUrl: '/v1/performance/level/import',
			type: '',
			currentForm: {},
			currentRowIndex: '',
			currentSelect: {},
			currentOptionsScore: [],
			loading: false,
			rules: {
				performanceId: [{ required: true, message: '请选择绩效考核', trigger: 'blur' }],
				orgId: [{ required: true, message: '请选择组织', trigger: 'blur' }],
				performanceScoreOrg: [{ required: true, message: '请选择组织绩效等级', trigger: 'blur' }]
			},
			rules2: {
				id: [{ required: true, message: '请选择绩效考核', trigger: 'blur' }],
				performanceScoreOrg: [{ required: true, message: '请选择组织绩效等级', trigger: 'blur' }]
			},
			rulesReturn: {},
			staffIdStatus: this.$store.state.app.staffId
		};
	},
	methods: {
		_getValue(eve) {
			this.searchForm.orgId = eve ? eve.id : '';
		},
		_getValue2(eve) {
			this.currentForm.orgId = eve ? eve.id : '';
		},
		_getList(page) {
			this.loading = true;
			page ? (this.searchForm.pageNo = page) : '';
			if (!this.orgIds) {
				performanceLevelList(this.searchForm).then((res) => {
					for (const key in res.list) {
						res.list[key].performanceCateNumber = this._returnJson(res.list[key].performanceCateNumber);
					}
					this.list = res.list;
					this.searchForm.totalNum = res.totalNum;
					this.loading = false;
				});
			} else {
				OrgList({
					pageNo: this.searchForm.pageNo,
					pageSize: this.searchForm.pageSize,
					performanceId: this.searchForm.performanceId,
					orgIds: this.orgIds
				}).then((res) => {
					for (const key in res.list) {
						res.list[key].performanceCateNumber = this._returnJson(res.list[key].performanceCateNumber);
					}
					this.list = res.list;
					this.searchForm.totalNum = res.totalNum;
					this.loading = false;
				});
			}
		},
		_handleSizeChange(val) {
			this.searchForm.pageSize = val;
			this._getList();
		},
		_handleCurrentChange(val) {
			this.searchForm.pageNo = val;
			this._getList();
		},
		_export() {
			const outStr = encodeURIComponent(JSON.stringify(this.searchForm));

			const url = `/v1/performance/level/export?params=${outStr}`;

			window.open(document.location.origin + url);
		},
		_download(val) {
			if (val) {
				const url = `/v1/performance/level/download/template?performanceId=${val}`;

				window.open(document.location.origin + url);
			}
		},
		_edit(item, index) {
			this.currentForm = {};
			const _item = item ? JSON.parse(JSON.stringify(item)) : {};

			this.currentRowIndex = index;
			this.currentForm = _item;
			if (item) {
				performanceScoreList({ screenType: 1, performanceId: _item.performanceId }).then((res) => {
					this.currentOptionsScore = res.list;
				});
			}
			this.rulesReturn = this.currentForm.id ? { ...this.rules2 } : { ...this.rules };
			for (const key in this.currentForm.performanceCateNumber) {
				this.rulesReturn[this.currentForm.performanceCateNumber[key].level] = [
					{ required: true, message: `请输入${key}等级人数`, trigger: 'blur' },
					{ validate: isIntegerNotMust, trigger: 'blur' }
				];
			}
			this.dialogVisible = true;
		},
		_returnJson(data) {
			return JSON.parse(JSON.parse(JSON.stringify(data)));
		},
		_save() {
			for (const key in this.currentForm.performanceCateNumber) {
				if (this.currentForm.performanceCateNumber[key].score === '') {
					this.$message.error(`请输入绩效等级${this.currentForm.performanceCateNumber[key].level}`);
					return false;
				}
			}
			this.$refs['currentForm'].validate((valid) => {
				if (valid) {
					const _form = { ...this.currentForm };

					_form.performanceCateNumber = JSON.stringify(this.currentForm.performanceCateNumber);
					this.loading = true;
					if (this.currentForm.id) {
						performanceLevelUpdate(_form).then((res) => {
							if (res.code == 0) {
								this.$set(this.list, this.currentRowIndex, this.currentForm);
								this.$message.success('修改成功');
								this.$forceUpdate();
							}
							this.dialogVisible = this.loading = false;
						});
					} else {
						performanceLevelAdd(_form).then((res) => {
							if (res.code == 0) {
								this._getList(this.searchForm.pageNo);
								this.$message.success('新增成功');
							}
							this.dialogVisible = this.loading = false;
						});
					}
				}
			});
		},
		_currentSelect(val) {
			if (!this.currentForm.id) {
				const _arr = this.options.filter((item) => item.key == val)[0].performanceCate.split(',');

				const _list = [];

				for (const key in _arr) {
					// let _obj = {'reduce': 0};
					// const _objD = {reduce : _arr[key]};
					// let  objs = Object.keys(_obj).reduce((newData, key) => {
					//     let newKey = _objD[key] || key
					//     newData[newKey] = _obj[key]
					//     return newData
					// }, {})
					const _obj = { level: _arr[key], score: 0 };

					_list.push(_obj);
				}
				this.currentSelect = this.currentForm.performanceCateNumber = _list;
			}
			performanceScoreList({ screenType: 1, performanceId: val }).then((res) => {
				this.optionsScoreNew = res.list;
			});
		},
		_getperformanceNameChoice() {
			performanceNameChoice({}).then((res) => {
				this.options = res.list;
				this.optionsNew = res.list.filter(
					(item) => item.selfEvaluationStatus == 1 && (item.processStatus == 2 || item.processStatus == 3 || item.processStatus == 4)
				);
				this.optionsDownload = res.list.filter((item) => item.selfEvaluationStatus == 1 && item.processStatus != 1);
			});
		},
		_getperformanceScoreList() {
			performanceScoreList({ screenType: 1 }).then((res) => {
				this.optionsScore = res.list;
			});
		},
		_updatePane() {
			this._getperformanceNameChoice();
			this._getperformanceScoreList();
			this._getList(this.searchForm.pageNo);
		},
		tableHeaderColor({ rowIndex }) {
			if (rowIndex === 0) {
				return 'background-color: #F0F8FF;';
			}
		}
	},
	mounted() {
		this._getperformanceNameChoice();
		this._getperformanceScoreList();
		this._getList();
	},
	watch: {
		dialogVisible() {
			if (this.$refs['currentForm'] !== undefined) {
				this.$nextTick(() => {
					this.$refs['currentForm'].clearValidate();
				});
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.box-card {
	margin-bottom: 20px;
	.box-card-top {
		line-height: 25px;
		font-family: PingFangSC-Semibold;
		font-size: 18px;
		color: #2b3642;
		letter-spacing: 0;
		span {
			font-family: PingFangSC-Regular;
			font-size: 14px;
			color: #2b3642;
			margin-left: 27px;
		}
		&:before {
			display: inline-block;
			width: 8px;
			height: 22px;
			background: #498df0;
			content: ' ';
			border-radius: 5px;
			vertical-align: middle;
			transform: translateY(-1px);
			margin-right: 8px;
		}
	}
	.box-card-line {
		height: 1px;
		background: transparent;
		margin: 10px 0 10px 0;
	}
	.box-card-bottom {
		line-height: 59px;
		font-size: 14px;
		color: #484848;
		.el-col {
			padding: 0 20px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
	.box-card-header {
		margin-bottom: 10px;
		.box-card-header-templateName {
			line-height: 32px;
		}
	}
	.box-card-footer-box {
		.el-row {
			background-color: #ebebeb;
			p {
				padding: 8px 4px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			.box-card-footer-box-right {
				text-align: center;
			}
		}
	}
}
.loser {
	.dialog-title {
		font-family: PingFangSC-Medium;
		font-size: 21px;
		letter-spacing: 0;
		line-height: 24px;
	}
	// ::v-deep.el-dialog__body{
	//     border-top: 1px solid #EAEDF1;
	// }
}
::v-deep.el-form-item__label {
	font-weight: normal;
}
</style>
